// TODO: Add correct values for all "domain.tld", "-placeholder" fields and
// manifest properties.

module.exports = {
  siteUrl: "https://www.achinosdesign.com/",
  siteLang: "en-us", // This should be the same as Prismic master locale
  title: "Achinos Design",
  shortTitle: "Achinos Design",
  description: "Achinos Design Interior Design and Architecture",
  logoUrl: "https://www.achinosdesign.com//images/logo.png",

  // Values used in manifest
  siteBackgroundColor: "#fff",
  siteThemeColor: "#000",
  siteIcon: "./src/images/icon.jpg",

  // Image Details used as fallback at metadata
  defaultImage: "https://www.achinosdesign.com//images/logo.png",
  defaultImageWidth: "512",
  defaultImageHeight: "512",
  defaultImageAlt: "image-alt-placeholder",
};
